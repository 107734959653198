.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dbd9de;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fafafa;
  position: fixed;
  bottom: 0;
}

.logo-footer {
  width: 90px;
  margin-right: 40px;
}

.desc-footer {
  display: flex;
  gap: 30px;
  align-items: center;
}

.link-footer {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #dbd9de;
  text-decoration: none;
  margin: 0;
}

.all-right {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #dbd9de;
  margin: 0;
  margin-left: 40px;
}
