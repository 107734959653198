.box-container-register {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 32px 0px #0000001c;
  border-radius: 20px;
  margin-bottom: 185px;
}

.sec-col {
  display: flex;
  flex-direction: column;
  margin-left: 165px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  width: 128px;
  border-radius: 4px;
  background-color: #f4f4f4;
  display: inline-block;
  cursor: pointer;
  padding: 24px;
  margin-bottom: 80px;
}

.inputfile + label * {
  pointer-events: none;
  width: 128px;
  height: 128px;
}

.check {
  display: flex;
  margin-bottom: 30px;
}

.label-check {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.12px;
  letter-spacing: -0.2px;
}
