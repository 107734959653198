.user-sidenav-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
}

.user-details {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 25px;
  border-bottom: 1px solid #f1f0f1;
}

.user-photo {
  width: 40px;
  height: 40px;
}

.name {
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #2d3748;
}

.email {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #b4bbbf;
}

.all-right-container {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 24px;
  padding-bottom: 10px;
}

.all-right-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 0;
  color: #b4bbbf;
}

a {
  color: #9bb4ff;
  text-decoration: none;
}
