.header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title-container {
  padding-right: 40px;
  padding-top: 40px;
}

.header-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  margin: 0;
}

.search-button-container {
  display: flex;
  gap: 80px;
  align-items: center;
  padding-left: 40px;
  padding-top: 40px;
}

.search-container {
  display: flex;
  position: relative;
  align-items: center;
}

.search-svg {
  position: absolute;
  left: 10px;
  color: #8a98a1;
}

.header-input {
  width: 450px;
  background-color: white;
  border: 1px solid #dbd9de;
  padding: 9px;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #8a98a1;
  border-radius: 6px;
}

.header-input::placeholder {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #8a98a1;
}

.header-add-invoice-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2551d4;
  background-color: transparent;
  border: none;
  gap: 8px;
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.2px;
}
