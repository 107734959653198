.main-dashboard-container {
  height: 100vh;
}

.two-square-container {
  display: flex;
  padding-top: 120px;
  padding-right: 120px;
  gap: 80px;
}

.one-square-container {
  display: flex;
  padding-right: 120px;
  padding-top: 80px;
}

.square {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 280px;
  height: 215px;
}

.square-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  margin: 0;
}

.square-content {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  margin: 0;
}
