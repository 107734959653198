.sidenav-container {
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 0;
  right: 0;
  background-color: white;
  border-left: 1px solid #f1f0f1;
  position: sticky;
  height: 100vh;
}

.sidenav-logo {
  align-self: center;
  width: 100px;
  margin-bottom: 65px;
  padding-top: 27px;
}

.navlink-list {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  gap: 10px;
  width: 240px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.12px;
  letter-spacing: -0.2px;
  text-align: right;
  text-decoration: none;
  color: #2d3748;
  border-top: 1px solid #f1f0f1;
  padding: 18px 24px 18px 60px;
  margin: 0;
  cursor: pointer;
}

.svg-icon {
  align-self: center;
}

nav a.active {
  outline-offset: -10px;
  outline: 10px solid white;
  background-color: #f0f4ff;
  border-radius: 18px;
  color: #0931a9;
}
