.page-container {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.logo {
  width: 145px;
  margin-bottom: 55px;
}

.box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 32px 0px #0000001c;
  border-radius: 20px;
  margin-bottom: 185px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #6e7981;
  margin: 0;
  margin-bottom: 80px;
}

.title-sec {
  font-size: 25px;
  font-weight: 600;
  color: #6e7981;
  margin: 0;
  margin-bottom: 80px;
  text-align: right;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  color: #6e7981;
  margin: 0;
  margin-right: 14px;
}

.input {
  width: 410px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #dbd9de;
  background-color: #fafafa;
  margin-bottom: 35px;
}

.submit-button {
  cursor: pointer;
  background-color: #436be3;
  width: 405px;
  height: 55px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 0.1px;
  border-radius: 10px;
  padding: 16px 20px;
  border: none;
  outline: none;
  margin-top: 80px;
}

.not-register-yet-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 40px;
}

.not-register-yet-title {
  font-family: IBM Plex Sans Hebrew;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #6e7981;
}

.link {
  color: #2551d4;
}
