.dots-popup-container {
  display: flex;
  align-items: center;
  background-color: white;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 100;
  box-shadow: 0px 4px 14px 0px #00000040;
  height: 120px;
  width: 200px;
  padding-right: 17px;
}

.link-list {
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 12px;
}

.popup-link {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: #2d3748;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.12px;
  letter-spacing: -0.2px;
  cursor: pointer;
  text-align: right;
}

.table {
  position: relative;
  width: 100%;
}

.table-container {
  overflow-y: scroll;
  height: 600px;
  margin: 40px;
}

table {
  border-spacing: 0;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding-right: 12px;
}

th {
  color: #2d3748;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  height: 40px;
  border-bottom: 1px solid #dbd9de;
}

td {
  height: 60px;
  color: #2d3748;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  border-bottom: 1px solid #f1f0f1;
}

.dots-container {
  position: relative;
}

.patients-dots {
  cursor: pointer;
}
