.add-patient-popup {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 40px;
  position: absolute;
  background-color: #ffffff;
  top: 130px;
  bottom: 130px;
  width: 770px;
  right: 30%;
  gap: 50px;
  box-shadow: 0px 2px 24px 0px #00000029;
  height: 650px;
}

.close-icon-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;
}

.add-patient-title {
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 40.64px;
  text-align: right;
  color: #2d3748;
}

.close-icon {
  cursor: pointer;
}

.add-patient-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  align-items: flex-start;
}

.add-patient-input-label {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #6e7981;
  padding-right: 15px;
  margin: 0;
  text-align: right;
  padding-bottom: 5px;
}

.add-patient-input {
  background-color: #fafafa;
  border: 1px solid #dbd9de;
  border-radius: 8px;
  padding: 20px;
  width: 329px;
}

.add-patient-input-check {
  margin-right: 15px;
  display: flex;
}

.add-patient-input-address {
  background-color: #fafafa;
  border: 1px solid #dbd9de;
  border-radius: 8px;
  padding: 20px;
  width: 732px;
}

.demand-container {
  padding: 40px;
}

.add-patient-form-container {
  width: 100%;
}

.demand-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40.64px;
  text-align: right;
  color: #2d3748;
  margin: 0;
}

.demand-form-container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #ffffff;
  margin-top: 40px;
  gap: 15px;
}

.demand-input-label {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  color: #6e7981;
  padding-right: 15px;
  margin: 0;
}

.demand-input {
  border-radius: 8px;
  border: 1px solid #dbd9de;
  background-color: #fafafa;
  width: 60%;
  padding: 20px;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  color: #2d3748;
}

.demand-input::placeholder {
  color: #2d3748;
}

.patients-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 64%;
  height: 120px;
  box-shadow: 0px 4px 14px 0px #00000040;
  overflow: scroll;
  margin-top: -15px;
}

.add-patient-button-list {
  border: none;
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #2551d4;
  cursor: pointer;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.patient-list-ul {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.12px;
  letter-spacing: -0.2px;
  text-align: right;
  width: 100%;
  margin: 0;
}

.list-item {
  margin: 0;
  width: 100%;
  text-align: right;
  padding-right: 15px;
  padding-top: 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.12px;
  letter-spacing: -0.2px;
  text-align: right;
  color: #2d3748;
  padding-bottom: 12px;
}

.list-item-container {
  width: 100%;
  display: flex;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #f1f0f1;
}

.list-item-container:hover {
  background-color: #fafafa;
}

.payment-container {
  width: 100%;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
}

.payment-form-container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid #dbd9de;
  border-top: 1px solid #dbd9de;
}

.input-label-container {
  display: flex;
  flex-direction: column;
}

.label-payment {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  color: #6e7981;
  padding-bottom: 15px;
}

.input-payment-amount {
  border-radius: 8px;
  background-color: #fafafa;
  border: 1px solid #dbd9de;
  height: 48px;
  width: 100px;
  padding-right: 12px;
}

.input-payment-service {
  border-radius: 8px;
  background-color: #fafafa;
  border: 1px solid #dbd9de;
  height: 48px;
  width: 500px;
  padding-right: 12px;
}

.input-payment-price {
  border-radius: 8px;
  background-color: #fafafa;
  border: 1px solid #dbd9de;
  padding-right: 12px;
  width: 160px;
  height: 48px;
}

.add-another-service-button {
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: center;
  color: #2551d4;
  border: none;
  align-self: flex-start;
  cursor: pointer;
  margin-top: 20px;
}

.calculate-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.12px;
  letter-spacing: -0.2px;
  color: #2d3748;
  margin: 0;
  padding-left: 120px;
}

.horizontal-line {
  border: 1px solid #dbd9de;
  width: 100%;
}

.calculate-text-all {
  font-size: 18px;
  font-weight: 600;
  line-height: 24.12px;
  letter-spacing: -0.2px;
  color: #2d3748;
  margin: 0;
  padding-left: 120px;
}

.calculate-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  gap: 15px;
}

.buttons-container {
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 24px;
  margin-top: 100px;
}

.continue-button {
  background-color: #436be3;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.1px;
  height: 55px;
  width: 200px;
}

.preview-button {
  background-color: transparent;
  border: 1px solid #436be3;
  color: #436be3;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.1px;
  border-radius: 10px;
  height: 55px;
  width: 200px;
}

.add-patient-button {
  border: none;
  background-color: #436be3;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.1px;
  height: 55px;
  width: 200px;
  margin-top: 50px;
  align-self: flex-end;
  cursor: pointer;
}

.service-list-container {
  height: 100px;
  overflow: scroll;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 4px 14px 0px #00000040;
}
