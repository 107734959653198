.back-to-patintes-button {
  background-color: #436be3;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.1px;
  align-self: flex-end;
}

.profile-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 40.64px;
  text-align: right;
  color: #6e7981;
  align-self: flex-start;
  margin: 0;
}

.profile-container {
  background-color: white;
  border-radius: 20px;
  width: 490px;
  box-shadow: 0px 1px 32px 0px #0000001c;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.profile-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

.profile-input-label {
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  text-align: right;
  color: #6e7981;
  margin: 0;
  padding-right: 10px;
}

.profile-input {
  border-radius: 8px;
  background-color: white;
  border: 1px solid #dbd9de;
  height: 48px;
  font-size: 17px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.2px;
  padding-right: 10px;
}

.profile-input:disabled {
  background-color: #fafafa;
}

.profile-save-button {
  background-color: #436be3;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.1px;
  width: 100%;
  cursor: pointer;
}
